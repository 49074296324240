<template>
  <v-container>
    <v-row>
      <v-col>
        <h1>Privacy Policy</h1>
        <p>Last updated: September 14, 2023</p>
        <p>By registering, you agree to this Privacy Policy.</p>

        <v-divider></v-divider>

        <h2>Data We Collect</h2>
        <p>We collect the following types of data:</p>
        <ul>
          <li>Your email address for account creation and authentication.</li>
          <li>Analytics data through Firebase Analytics and Hotjar.</li>
          <li>Payment data through Stripe.</li>
          <li>YouTube profile data via the YouTube Data API when you authenticate using your YouTube account.</li>
          <li>VidToBlog's use and transfer of information received from Google APIs to any other app will adhere to <a
              href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes"
              target="_blank">Google API Services User Data Policy</a>, including the Limited Use requirements.</li>
          <li>Captions of YouTube videos that you submit.</li>
        </ul>

        <v-divider></v-divider>

        <h2>Types of Data</h2>
        <ul>
          <li>Personal Data: Email, YouTube profile information</li>
          <li>Analytics Data: Collected through Firebase Analytics and Hotjar</li>
          <li>Payment Data: Collected through Stripe</li>
          <li>Content Data: Captions of YouTube videos you submit</li>
          <li>Processed Data: Generated blog posts</li>
        </ul>

        <v-divider></v-divider>

        <h2>How We Use Data</h2>
        <p>We use your data for the following purposes:</p>
        <ul>
          <li>Email is used for authentication and communication.</li>
          <li>Analytics data collected through Firebase Analytics and Hotjar is used to improve user experience and for
            internal analytics.</li>
          <li>Payment data is used to process transactions.</li>
          <li>YouTube profile data is used to authenticate your YouTube account and fetch video captions.</li>
          <li>Captions are used to generate blog posts through our AI algorithms.</li>
        </ul>

        <v-divider></v-divider>

        <h2>Data Sharing</h2>
        <p>We are committed to protecting your privacy and handle your data with the utmost care. The following outlines
          our data sharing practices:</p>

        <ul>
          <li><strong>Limited Sharing:</strong> We do not share your data with third parties except as required by law, as
            necessary to provide our services, or with trusted partners like payment processors (e.g., Stripe) and
            analytics providers (e.g., Hotjar).</li>

          <li><strong>Use of Third-Party AI Models:</strong> For the purpose of converting your video captions into blog
            posts, we share your video captions with third-party AI models, specifically those provided by OpenAI. This
            sharing is essential for the functionality of the VidToBlog service and is conducted with your explicit
            consent.</li>

          <li><strong>No Further Sharing:</strong> Your video captions and the generated blog posts are not shared with
            any other third parties unless explicitly specified or authorized by you.</li>

          <li><strong>User Control:</strong> You have control over your data. We ensure that your data is used strictly
            for the intended purpose of our service and not for any unrelated activities.</li>
        </ul>

        <v-divider></v-divider>


        <h2>Consent for Data Sharing</h2>
        <p>In compliance with data protection regulations and to ensure the ethical use of our users' data, VidToBlog
          requires explicit user consent for data processing activities involving third-party AI models, such as those
          provided by OpenAI, for the purpose of converting video captions into blog posts.</p>

        <h3>Mandatory Consent:</h3>
        <ul>
          <li><strong>Requirement for Service Use:</strong> Consent to share your video captions with AI models for blog
            post generation is mandatory to use the VidToBlog service. This consent is obtained through a clearly labeled
            checkbox within the application interface.</li>
          <li><strong>Clear and Informed Consent:</strong> Before using our service, you will be presented with the option
            to consent via a checkbox, accompanied by the statement: "I consent to sharing my video captions with AI
            models for blog post generation."</li>
          <li><strong>No Implicit Consent:</strong> The VidToBlog service will not be accessible unless this checkbox is
            actively checked by the user. This measure ensures that consent is explicitly given and is not implied or
            assumed.</li>
          <li><strong>Purpose of Data Sharing:</strong> The data shared upon your consent is used solely for the purpose
            of generating blog posts. It is not used for any other activities or shared with other third parties beyond
            what is necessary for this specific purpose.</li>
          <li><strong>Revocation of Consent:</strong> While consent is necessary to use the service, it is not
            irreversible. You may revoke your consent at any time, but this will result in the inability to continue using
            the VidToBlog service for converting video captions to blog posts.</li>
        </ul>

        <p>By ensuring that consent is a prerequisite for the use of VidToBlog, we uphold our commitment to data privacy
          and comply with relevant regulations. This approach ensures that our users have complete control over their data
          and understand the terms of its use within our service.</p>


        <v-divider></v-divider>

        <h2>Data Retention</h2>
        <p>Your data is stored in Firebase Firestore, which is compliant with standard data protection laws. YouTube video
          captions and generated blog posts are not retained in our database.</p>

        <v-divider></v-divider>

        <h2>Children's Privacy</h2>
        <p>Our services are not intended for individuals under the age of 13. We do not knowingly collect personal
          information from children under 13.</p>

        <v-divider></v-divider>

        <h2>Third-Party Services</h2>
        <p>We use third-party services like Firebase Analytics, Stripe, and Hotjar for analytics, payment processing, and
          user experience optimization. These services may collect information used to identify you. We do not control
          these third-party services or their privacy policies, which may differ from ours. We recommend that you read the
          privacy policies of these services.</p>

        <v-divider></v-divider>

        <h2>Changes to This Privacy Policy</h2>
        <p>We reserve the right to update this Privacy Policy at any time. We will notify you of any changes by posting
          the new Privacy Policy on this page.</p>

        <v-divider></v-divider>

        <h2>Governing Law</h2>
        <p>This Privacy Policy is governed by the laws of Ontario, Canada.</p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "UpdatedPrivacyPolicy"
};
</script>
